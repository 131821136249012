var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "x"
  }, [_c('div', {
    staticClass: "menu opening"
  }, [_c('el-select', {
    staticClass: "width",
    attrs: {
      "placeholder": "请选择",
      "clearable": ""
    },
    model: {
      value: _vm.activityId,
      callback: function callback($$v) {
        _vm.activityId = $$v;
      },
      expression: "activityId"
    }
  }, _vm._l(_vm.openingList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item.activityName,
        "value": item.id
      }
    });
  }), 1), _c('div', {
    staticClass: "mt20"
  }, [_c('p', {
    staticClass: "fs16 bold lh40 bdb"
  }, [_vm._v(" " + _vm._s(_vm.treeType ? "楼层-区域" : "总平图") + " ")]), _vm.treeType ? _c('el-tree', {
    ref: "treeForm",
    attrs: {
      "node-key": "id",
      "data": _vm.treeData,
      "check-strictly": "",
      "props": _vm.defaultProps,
      "show-checkbox": ""
    },
    on: {
      "check-change": _vm.handleClick
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "main flex1 opening x"
  }, [_c('div', {
    staticClass: "img-box flex1"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.imagePath,
      expression: "!imagePath"
    }],
    staticClass: "width height y1 aic"
  }, [_c('img', {
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "src": require("../../../assets/addImg.png")
    }
  }), _c('p', {
    staticClass: "color6 mt8"
  }, [_vm._v("请上传总平图")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.imagePath,
      expression: "imagePath"
    }],
    staticClass: "img-cell",
    style: _vm.imgStyle,
    attrs: {
      "id": "img-cell"
    },
    on: {
      "mousedown": function mousedown(e) {
        return _vm.start(e, 'img');
      },
      "mouseup": _vm.stop,
      "mousemove": _vm.move
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.imagePath,
      "draggable": false
    }
  }), _vm._l(_vm.showTagList, function (item, index) {
    return _c('div', {
      key: item.buildingId,
      staticClass: "img-tag pointer y aic",
      class: {
        active: _vm.activeTag == item.buildingId
      },
      style: {
        'z-index': _vm.activeTag == item.buildingId ? 99 : index,
        left: "".concat(item.coordinateLeft, "px"),
        top: "".concat(item.coordinateTop, "px")
      },
      attrs: {
        "id": "tag".concat(item.buildingId)
      },
      on: {
        "mousedown": function mousedown($event) {
          $event.stopPropagation();
          return function (e) {
            return _vm.start(e, item.buildingId);
          }.apply(null, arguments);
        },
        "mouseup": function mouseup($event) {
          $event.stopPropagation();
          return _vm.stop.apply(null, arguments);
        },
        "mousemove": function mousemove($event) {
          $event.stopPropagation();
          return _vm.move.apply(null, arguments);
        },
        "dblclick": function dblclick($event) {
          return _vm.removeTag(item);
        }
      }
    }, [_c('div', {
      staticClass: "tag-main x1 aic"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "tag-triangle"
    })]);
  })], 2)]), _c('div', {
    staticClass: "setting height y3"
  }, [_c('div', [_c('p', {
    staticClass: "fs16 bold lh40 bdb mb8"
  }, [_vm._v("楼层-区域")]), _c('p', {
    staticClass: "fs13 color9 mb12"
  }, [_vm._v(" 最小尺寸800px * 600px，推荐比例4:3，支持jpg、png格式，建议大小5M以内 ")]), _c('f-upload', {
    ref: "picUploader",
    staticClass: "mb8",
    attrs: {
      "type": "4",
      "title": "创建总平图",
      "isCompress": false
    },
    on: {
      "on-success": _vm.imgUploadSuccess
    }
  }), _c('div', {
    staticClass: "h40 bdb mb8 x3 aic"
  }, [_c('p', {
    staticClass: "fs16 bold"
  }, [_vm._v("设置区域位置坐标")]), _c('el-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.checkedItem.layoutType !== 'PARKING',
      expression: "checkedItem.layoutType !== 'PARKING'"
    }],
    attrs: {
      "type": "primary",
      "size": "mini",
      "loading": _vm.btnDis
    },
    on: {
      "click": _vm.autoImport
    }
  }, [_vm._v(" 自动识别 ")])], 1), _vm.imagePath ? [_c('div', {
    staticClass: "tag-box"
  }, _vm._l(_vm.showBlockList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "tag pointer x1 aic",
      on: {
        "click": function click($event) {
          return _vm.swithTag(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name || item.parkingPlaceNo) + " ")]);
  }), 0), _vm.checkedItem.layoutType !== 'PARKING' && _vm.blockCheckList.length > _vm.showBlockNum ? _c('div', {
    staticClass: "x1"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "disabled": _vm.pageIndex <= 1
    },
    on: {
      "click": function click($event) {
        return _vm.setPage(false);
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-caret-top"
  })]), _c('el-button', {
    attrs: {
      "type": "text",
      "disabled": _vm.showNextBtn
    },
    on: {
      "click": function click($event) {
        return _vm.setPage(true);
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-caret-bottom"
  })])], 1) : _vm._e()] : _vm._e()], 2), _c('div', [_vm._m(0), _c('el-button', {
    staticClass: "width",
    attrs: {
      "type": "primary",
      "disabled": !_vm.imagePath
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 保存 ")])], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fs13 color9 mb12"
  }, [_c('p', [_vm._v("操作说明")]), _c('p', [_vm._v("第一步：点击右侧区域标签，生成到总平图；")]), _c('p', [_vm._v(" 第二步：按住区域标签，拖动到对应位置，系统将自动获取该位置坐标值，然后点击保存按钮； ")])]);

}]

export { render, staticRenderFns }